import React from "react";
import { Card, Button, Image } from "react-bootstrap";
import { LinkPreview } from "@dhaiwat10/react-link-preview";

const Post = ({ post, putPost, deletePost }) => {
    const htmlEncode = (str) => {
        // very basic sanitization to prevent XSS injections
        var ESC_MAP = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
        };
        return String(str).replace(/[&<>"]/g, function (c) {
            return ESC_MAP[c];
        });
    };

    const renderContent = (post) => {
        if (post.type === "text") {
            return <p>{htmlEncode(post.content)}</p>;
        } else if (post.type === "img") {
            return (
                <Image src={post.content} alt={`Post ${post.title}`} fluid />
            );
        } else if (post.type === "video") {
            return (
                <video
                    src={post.content}
                    style={{ maxWidth: 100 + "%" }}
                    controls
                ></video>
            );
        } else if (post.type === "link") {
            return (
                <LinkPreview
                    url={post.content}
                    fallback={
                        <a href={post.content}>
                            <p>{post.content}</p>
                        </a>
                    }
                />
            );
        }
        return <p>Hmm ... that post type doesn't seem to exist.</p>;
        // should not ever get here, unless you make manual malformed JSON requests
    };

    const upvote = () => {
        post.votes += 1;
        putPost(post);
    };

    const sparkleVote = () => {
        // magically applies a random number of votes - sort of like a supervote!
        post.votes += Math.floor(Math.random() * (15 - 5) + 5);
        putPost(post);
    };

    const vomitVote = () => {
        // for when you really hate a post, -5 vote
        post.votes = Math.max(0, post.votes - 5);
        putPost(post);
    };

    const del = () => {
        deletePost(post);
    };

    return (
        <Card className="mt-2 mb-2">
            <Card.Body>
                <h2>{htmlEncode(post.title)}</h2>
                <hr />
                {renderContent(post)}
            </Card.Body>
            <Card.Footer className="text-muted">
                @{htmlEncode(post.username)} - {post.votes} votes{" "}
                <Button
                    variant="outline-primary"
                    className="me-2"
                    onClick={() => upvote()}
                >
                    ⬆️
                </Button>
                <Button
                    variant="outline-warning"
                    className="me-2"
                    onClick={() => sparkleVote()}
                >
                    ✨
                </Button>
                <Button
                    variant="outline-success"
                    className="me-2"
                    onClick={() => vomitVote()}
                >
                    🤮
                </Button>
                <Button
                    variant="outline-danger"
                    className="float-sm-end"
                    onClick={() => del()}
                >
                    🗑
                </Button>
            </Card.Footer>
        </Card>
    );
};

export default Post;
