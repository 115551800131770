import React from "react";

import { Alert } from "react-bootstrap";

const AlertComp = ({ alert }) => {
    if (alert === null) {
        return null;
    }
    return <Alert>{alert}</Alert>;
};

export default AlertComp;
