import axios from "axios";
const baseUrl = "https://general_assignment.kewbish.workers.dev/posts";

const getAll = () => {
    const req = axios.get(baseUrl);
    return req.then((response) => response.data);
};

const create = (newObj) => {
    const req = axios.post(baseUrl, newObj);
    return req.then((response) => response.data);
};

const update = (posts) => {
    const req = axios.put(baseUrl, posts);
    return req.then((response) => response.data);
};

const service = {
    getAll,
    create,
    update,
};

export default service;
