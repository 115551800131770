import React from "react";
import { Navbar } from "react-bootstrap";

const BotBar = () => {
    return (
        <Navbar fixed="bottom" bg="light">
            <Navbar.Text className="ms-3">
                Cloudflare Summer Internship Application 2022 :)
            </Navbar.Text>
        </Navbar>
    );
};

export default BotBar;
