import React, { useState } from "react";

import { Accordion, Form, Button } from "react-bootstrap";

const AddPost = ({ setAlert, addNewPost, user }) => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [type, setType] = useState("text");

    const isValidUrl = (urlString) => {
        let url;
        try {
            url = new URL(urlString);
        } catch (_) {
            return false;
        }
        return url.protocol === "https:" || url.protocol === "http:";
    };

    const createNewPost = (event) => {
        event.preventDefault();
        if (
            type === "img" &&
            !(
                content.endsWith("png") ||
                content.endsWith("jpg") ||
                content.endsWith("jpeg") ||
                content.endsWith("svg") ||
                content.endsWith("webp") ||
                content.endsWith("gif")
            )
        ) {
            // very naive checker - would certainly do a more extensive check, and would allow users to actually upload their documents in a production app
            setAlert("Error: your image link isn't a proper image.");
            setTimeout(() => setAlert(null), 5000);
            return;
        }
        if (
            type === "video" &&
            !(
                content.endsWith("mp4") ||
                content.endsWith("webm") ||
                content.endsWith("ogg")
            )
        ) {
            setAlert("Error: your video link isn't a proper video.");
            setTimeout(() => setAlert(null), 5000);
            return;
        }
        if (type === "link" && !isValidUrl(content)) {
            setAlert("Error: link is invalid URL.");
            setTimeout(() => setAlert(null), 5000);
            return;
        }
        const newPost = {
            type: type,
            title: title,
            content: content,
            username: user,
        };
        addNewPost(newPost);
    };

    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Create a new post</Accordion.Header>
                <Accordion.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                value={title}
                                onChange={(event) =>
                                    setTitle(event.target.value)
                                }
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formContent">
                            <Form.Label>Content</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter content (i.e. image link, YouTube link, other link)"
                                as="textarea"
                                value={content}
                                onChange={(event) =>
                                    setContent(event.target.value)
                                }
                            />
                        </Form.Group>

                        <Form.Select
                            className="mb-3"
                            aria-label="Select post type"
                            defaultValue="text"
                            onChange={(event) => setType(event.target.value)}
                        >
                            <option value="text">Text</option>
                            <option value="img">Image or GIF</option>
                            <option value="video">Video Link</option>
                            <option value="link">Link</option>
                        </Form.Select>

                        <Button
                            variant="primary"
                            type="submit"
                            onClick={(event) => createNewPost(event)}
                        >
                            Submit
                        </Button>
                    </Form>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default AddPost;
