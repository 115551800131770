import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";

import TopBar from "./components/TopBar";
import AddPost from "./components/AddPost";
import Post from "./components/Post";
import BotBar from "./components/BotBar";
import AlertComp from "./components/Alert";

import workerService from "./services/worker";

const App = () => {
    const [user, setUser] = useState("kewbish");
    const [posts, setPosts] = useState([]);
    const [alert, setAlert] = useState(null);

    useEffect(() => {
        workerService.getAll().then((initialPosts) => {
            setPosts(initialPosts);
        });
    }, []);

    const addNewPost = (newPost) => {
        workerService
            .create(newPost)
            .then((post) => {
                setPosts(posts.concat(post));
                setAlert("Post added!");
                setTimeout(() => setAlert(null), 5000);
            })
            .catch((error) => {
                setAlert("Error:", error);
                setTimeout(() => setAlert(null), 5000);
            });
    };

    const deletePost = (delPost) => {
        const newPosts = posts.filter((post) => post.id !== delPost.id);
        updatePosts(newPosts);
    };

    const putPost = (newPost) => {
        const otherPosts = posts
            .filter((post) => post.id !== newPost.id)
            .concat(newPost);
        const sortedPosts = otherPosts.sort((a, b) => b.votes - a.votes);
        updatePosts(sortedPosts);
    };

    const updatePosts = (posts) => {
        workerService
            .update(posts)
            .then((updatedPosts) => {
                setPosts(updatedPosts);
                setAlert("Updated!");
                setTimeout(() => setAlert(null), 5000);
            })
            .catch((error) => {
                setAlert("Error:", error);
                setTimeout(() => setAlert(null), 5000);
            });
    };

    return (
        <div className="App">
            <TopBar user={user} setUser={setUser} />
            <Container className="mt-5 mb-5">
                <Row>
                    <Col></Col>
                    <Col xs={6}>
                        <AlertComp alert={alert}></AlertComp>
                        <AddPost
                            addNewPost={addNewPost}
                            setAlert={setAlert}
                            user={user}
                        />
                        <div>
                            {posts.map((post) => (
                                <Post
                                    post={post}
                                    key={post.id}
                                    putPost={putPost}
                                    deletePost={deletePost}
                                />
                            ))}
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </Container>
            <br />
            <BotBar />
        </div>
    );
};

export default App;
