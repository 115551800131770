import React from "react";

import { Container, Navbar, InputGroup, FormControl } from "react-bootstrap";

const TopBar = ({ user, setUser }) => {
    const handleUserChange = (event) => {
        setUser(event.target.value);
    };

    return (
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand>Emilie's Brain 🧠</Navbar.Brand>
                <InputGroup className="mt-1 w-25">
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <FormControl
                        placeholder="Username"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={user}
                        onChange={handleUserChange}
                    />
                </InputGroup>
            </Container>
        </Navbar>
    );
};

export default TopBar;
